import React from 'react';

import {
  FlxContainer1,
  MaxContainer,
  Section,
  SectionWrapper,
  AboutHeader,
  AboutDescription,
} from '../style';

const About = function () {
  return (
    <Section>
      <MaxContainer>
        <SectionWrapper>
          <FlxContainer1>
            <AboutHeader>About Us</AboutHeader>
            <AboutDescription>
              We at Edification have noticed a deficit of resources in low
              income neighborhoods. We believe that a lack of these specific
              resources creates long term issues such as gun violence and mental
              illness. Other issues include lack of proficiency, or guidance and
              discipline. Our goal is to create an opportunity for low income
              communities to access the resources needed to tackle the
              challenges they are faced with.
            </AboutDescription>
          </FlxContainer1>
        </SectionWrapper>
      </MaxContainer>
    </Section>
  );
};

export default About;
