import React from 'react';
import { Link } from 'gatsby';

import SCImageContainer from './SCImageContainer';

import {
  BodyWrapper,
  DarkBG,
  ShowcaseBody,
  Title,
  ShowcaseSection,
  ShowcaseWrapper,
  Description,
  LinkWrapper,
  AbsoluteContainer,
} from '../style';

const ShowcaseContainer = function () {
  return (
    <>
      <DarkBG />
      <AbsoluteContainer>
        <SCImageContainer />
      </AbsoluteContainer>
      <ShowcaseSection id="showcase">
        <ShowcaseWrapper>
          <BodyWrapper>
            <ShowcaseBody>
              <Title>A Community Is Strong Together.</Title>
              <Description>
                A non-profit organization for the betterment of low income
                communities
              </Description>
              <LinkWrapper>
                <Link to="#about">Find Out More</Link>
              </LinkWrapper>
            </ShowcaseBody>
          </BodyWrapper>
        </ShowcaseWrapper>
      </ShowcaseSection>
    </>
  );
};

export default ShowcaseContainer;
