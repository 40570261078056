import React from 'react';

import useResponsive from '../hooks/useResponsive';
import { Showcase, About } from '../components';
import useScrollDir from '../hooks/useScrollDir';
import Mission from '../components/Mission';

const IndexPage = () => {
  useResponsive();
  useScrollDir();
  return (
    <>
      <Showcase />
      <About />
      <Mission />
    </>
  );
};

export default IndexPage;
