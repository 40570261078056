import { useStaticQuery, graphql } from 'gatsby';

const useShowcaseImages = function () {
  const { allFile } = useStaticQuery(graphql`
    query Image {
      allFile(filter: { relativePath: { regex: "/" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  return {
    mobileImg: allFile.edges[0].node,
    desktopImg: allFile.edges[1].node,
  };
};

export default useShowcaseImages;
