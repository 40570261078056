import { useState, useEffect } from 'react';

const useScrollDir = function () {
  const [scrollDir, setScrollDir] = useState('scrolling down');

  useEffect(() => {
    const threshold = 0;
    let lastScrollPos = window.scrollY;
    let ticking = false;

    let header = document.querySelector('header');

    handleHeader(scrollDir, header, lastScrollPos);

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };

    function handleHeader(direction, headElem, pos) {
      if (direction === 'scrolling up') {
        if (pos > 830) {
          headElem.classList.add('scroll-up');
        } else if (pos < 300) {
          headElem.classList.add('scroll-up-exit');
          headElem.classList.remove('scroll-down', 'scroll-up');
        }
      } else if (direction === 'scrolling down') {
        if (pos > 90) {
          headElem.classList.add('scroll-down');
          if (headElem.classList.contains('scroll-up-exit')) {
            headElem.classList.remove('scroll-up-exit');
          }
        }
      }
    }

    function updateScrollDir() {
      let currYPos = window.scrollY;

      if (Math.abs(currYPos - lastScrollPos) < threshold) {
        ticking = false;
        return;
      }

      handleHeader(scrollDir, header, currYPos);

      setScrollDir(
        currYPos > lastScrollPos ? 'scrolling down' : 'scrolling up'
      );
      lastScrollPos = currYPos > 0 ? currYPos : 0;
      ticking = false;
    }

    function onScroll() {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    }
  }, [scrollDir]);
};

export default useScrollDir;
