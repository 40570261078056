import React, { useContext } from 'react';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import AppContext from '../context/AppContext';
import useShowcaseImages from '../hooks/useShowcaseImages';

import { ImgContainer, ImgWrapper } from '../style/showcase';

const SCImageContainer = function () {
  const appContext = useContext(AppContext);

  const { device } = appContext;

  const { desktopImg, mobileImg } = useShowcaseImages();

  let image;

  let altString;

  if (device === 'desktop') {
    image = getImage(desktopImg);
    altString = 'A sandy beach located in Coney Island';
  } else {
    image = getImage(mobileImg);
    altString = 'A boardwalk located in Coney Island';
  }

  return (
    <ImgContainer>
      <ImgWrapper>
        <GatsbyImage
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
          }}
          image={image}
          alt={altString}
        />
      </ImgWrapper>
    </ImgContainer>
  );
};

export default SCImageContainer;
