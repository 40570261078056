import React from 'react';
import { Section, MaxContainer, FlxContainer1, SectionWrapper } from '../style';
import {
  MissionHeader,
  MissionList,
  MissionListItem,
  MissionStatement,
  ListTitle,
} from '../style/mission';

const Mission = function () {
  return (
    <Section
      style={{
        backgroundColor: '#d3a999',
      }}
    >
      <MaxContainer>
        <SectionWrapper>
          <FlxContainer1>
            <MissionHeader>The Mission</MissionHeader>
            <MissionStatement>
              Our mission is to bridge the gap between low income communities
              and the resources that are needed. After much research and first
              hand experiences, we have narrowed it down to 4 categories which
              we believe are crucial for change.
            </MissionStatement>
            <ListTitle>Categories</ListTitle>
            <MissionList>
              <MissionListItem>Nutrition</MissionListItem>
              <MissionListItem>Mental Wellness</MissionListItem>
              <MissionListItem>Spirituality</MissionListItem>
              <MissionListItem>Financial Literacy</MissionListItem>
            </MissionList>
          </FlxContainer1>
        </SectionWrapper>
      </MaxContainer>
    </Section>
  );
};

export default Mission;
