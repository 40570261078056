import styled from '@emotion/styled';
import { mq } from '../utils';

export const MissionHeader = styled.h2`
  text-align: center;
  padding: 1.5rem 0;
  font-family: ${(props) => props.theme.headingFont};

  ${mq('iphoneSE', 'min')} {
    width: 100%;
    font-size: ${(props) => props.theme.header2};
  }

  ${mq('desktopS', 'min')} {
    width: 50%;
    font-size: 4.5rem;
  }
`;

export const MissionStatement = styled.p`
  text-align: center;
  padding: 1rem 0;
  font-family: ${(props) => props.theme.paragraphFont};

  ${mq('iphoneSE', 'min')} {
    width: 80%;
    font-size: ${(props) => props.theme.paragraph};
    line-height: 2;
    text-align: center;
  }

  ${mq('tablet', 'min')} {
    width: 50%;
    text-align: left;
    line-height: 3;
  }

  ${mq('desktopS', 'min')} {
    width: 50%;
    font-size: ${(props) => props.theme.header5};
    line-height: 3;
    text-align: left;
  }
`;

export const ListTitle = styled.p`
  ${mq('iphoneSE', 'min')} {
    width: 80%;
    font-size: ${(props) => props.theme.header3};
    line-height: 2;
    text-align: center;
  }

  ${mq('tablet', 'min')} {
    text-align: left;
    width: 50%;
    line-height: 3;
  }

  ${mq('desktopS', 'min')} {
    width: 50%;
    font-size: ${(props) => props.theme.header2};
    line-height: 3;
    text-align: left;
  }
`;

export const MissionList = styled.ul`
  ${mq('iphoneSE', 'min')} {
    width: 80%;
  }

  ${mq('tablet', 'min')} {
    width: 50%;
  }
`;

export const MissionListItem = styled.li`
  padding: 1rem 0;
`;
